<template>
  <div style="padding-bottom: 10px;">
      <el-table
          :data="tableData"
          border
          style="width: 100%; margin-bottom: 20px"
      >
          <el-table-column label="序号" width="150" type="index" :index="indexMethod">
          </el-table-column>
          <el-table-column prop="city" label="城市" width="120">
          </el-table-column>
          <el-table-column prop="country" label="区县" width="120">
          </el-table-column>
          <el-table-column prop="name" label="企业名称" width="120">
          </el-table-column>
          <!-- 暂时去掉 -->
          <!-- <el-table-column
      prop="supervision"
      label="督导归属"
      width="300"
    >
    </el-table-column> -->
		
		<el-table-column prop="groups" label="所属企业组" width="180">
							<template slot-scope="scope">
								<div>
									<el-tag
										style="margin-bottom: 5px"
										>{{ scope.row.groupName }}</el-tag
									>
								</div>
							</template>
						</el-table-column> 
          <!-- <el-table-column prop="type" label="企业标签">
              <template slot-scope="scope">
                  <el-tag effect="plain">
                      {{ scope.row.type | companyType }}
                  </el-tag>
              </template>
          </el-table-column> -->
          <el-table-column prop="activityNumber" label="活跃度">
              <template slot-scope="scope">
                {{ scope.row.activityNumber > 2 ? '活跃' : '不活跃' }}
              </template>
          </el-table-column>
          <el-table-column prop="dateTime" label="填报状态">
              <template slot-scope="scope">
                <span :class="scope.row.dateTime?'color:green':'color:#333'">
                   {{ scope.row.dateTime?'已填报':'未填报' }}
                </span>
              </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                  <el-button
                      v-if="scope.row.dateTime"
                      @click="handleClick(scope.row)"
                      type="text"
                      size="small"
                      >本次填报数据</el-button
                  >
                  <el-button type="text" size="small" @click="historyClick(scope.row)"
                      >历史填报数据</el-button
                  >
              </template>
          </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div>
          <Pagination
              v-bind:child-msg="pageparm"
              @callFather="callFather"
          ></Pagination>
      </div>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { workbenchListApi } from '@/api/workbench'
export default {
  // 注册组件
  components: {
      Pagination,
  },
  data() {
      return {
          tableData: [],
          pageparm: {
              currentPage: 1,
              pageSize: 10,
              total: 20,
          },
      }
  },
  created() {
      //this.workbenchListFun()
  },
  methods: {
		indexMethod(index) {
			const page = this.formInline.page ? this.formInline.page : 1;
			const limit = this.formInline.limit ? this.formInline.limit : 10;
			return (page - 1) * limit +  index + 1;
		},
    onLoad(wjdoId,value){
      this.wjdoId = wjdoId
      this.formInline = value
      this.workbenchListFun()
    },
			//导出
			onExport(wjdoId,value){
				var params = {
					wjdoId,
				}
				var p = Object.assign(params, value)
				console.log('p',p);
				this.download2(
					"/doftec/admin-screen/company/export",
					"企业列表.xls",
					p
      	);
				this.$emit('closeLoad')
			},
      // 请求公司填报数据
      async workbenchListFun(currentPage, pageSize) {
          this.tableData = []
          var start = currentPage ? currentPage * pageSize - pageSize : 0
					const limit = this.formInline.limit ? this.formInline.limit : 10;
          var params = {
              start: start,
              length: limit,
              draw: 1,
              wjdoId: this.wjdoId,
          }
          var p = Object.assign(params, this.formInline)
          var res = await workbenchListApi(p)
          // console.log('公司填报数据', res);
          this.tableData = res.data
          this.pageparm.total = res.recordsTotal
      },
      // 分页插件事件
      callFather(parm) {
          this.formInline.page = parm.currentPage
          this.formInline.limit = parm.pageSize
          this.workbenchListFun(parm.currentPage, parm.pageSize)
      },
      handleClick(row) {
          this.$router.push({
              path: '/workbench/thisReportting',
              query:{
                id:this.wjdoId,
                uscc:row.uscc,
                name: row.name
              }
          })
      },

      historyClick(row) {
          this.$router.push({
              path: '/workbench/historicalReporting',
              query:{
                  uscc:row.uscc
              }
          })
      },
  },
}
</script>
<style scoped></style>
