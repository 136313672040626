<template>
  <div class="box">
    <div class="title-header">
      <!-- 标题 -->
      <div class="title-box">
        <el-row
          type="flex"
          justify="space-between"
          align="middle"
        >
          <el-col>
            <el-row
              type="flex"
              align="middle"
              :gutter="24"
            >
              <el-col :span="23">
                <div class="title-tit-box">
                  <div class="title-tit" style="margin-right:10px">{{title}}（{{cityName}}）</div>
                 <el-dropdown @command="monthClick">
                   <span class="el-dropdown-link">
                    <el-button type="text">切换问卷</el-button><i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="dropdown-box">
                    <el-dropdown-item v-for="(item, index) in monthList" :key="index" :command="item">{{item.wjdoTitle}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col>
            <el-row type="flex">
              <el-col class="title-t"><span class="title-tit">{{allObj.submitTotal?allObj.submitTotal:0}}</span>家企业参与</el-col>
              <el-col class="title-t">
                填报率：<span class="title-pro">{{allObj.submitRate?allObj.submitRate:0}}</span>
              </el-col>
              <!-- <el-col class="title-t">
                准确率：<span class="title-pro">{{allObj.h1?allObj.h1.zql:0}}%</span>
              </el-col> -->
            </el-row>
          </el-col>
        </el-row>

      </div>
      <!-- 搜索条件 -->
      <!-- <div class="header-box">
        <el-row type="flex">
          <el-col>
            <span class="header-title">区县：</span>
            <el-select
              v-model="form.countyCode"
              placeholder="请选择"
              clearable
              no-data-text="请先选择城市"
            >
              <el-option
                v-for="(item,index) in districtList"
                :key="index"
                :label="item.areaName"
                :value="item.areaCode"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col>
            <span class="header-title">企业标签：</span>
            <el-select
              v-model="form.companyType"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in companyList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col>
            <span class="header-title">填报状态：</span>
            <el-select
              v-model="form.type"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in reportList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div> -->
    </div>
    <!-- 内容 -->
    <div class="content-box" >
      <div class="content-top" element-loading-text="首次获取统计数据会比较慢，请耐心等待，之后会恢复正常"  v-loading="loading">
        <el-row
          :gutter="24"
          type="flex"
          justify="space-between"
        >
          <el-col :span="24">
            <div class="content-top-left">
              <leftList ref="leftList"></leftList>
            </div>
          </el-col>
          <el-col :span="8"  v-show="false">
            <div class="content-top-right">
               <el-row type="flex" class="title">
                <el-col style="text-align:right">
                  <b>得分列表</b>
                </el-col>
                <el-col style="text-align:right">
                  <span style="font-weight:400">
                    我的排名：{{ranking}}
                  </span>
                </el-col>
              </el-row>
              <rightList ref="rightList"></rightList>
            </div>
          </el-col>
        </el-row>
      </div>
			<div class="content-bottom">
      <!-- 搜索条件 -->
      <div class="header-box">
        <el-row type="flex" align="bottom" justify="space-between">
          <!-- <el-col>
            <span class="header-title">城市：</span>
            <el-select
              v-model="form.cityCode"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="(item,index) in city"
                :key="index"
                :label="item.areaPname"
                :value="item.areaPcode"
              >
              </el-option>
            </el-select>
          </el-col> -->
          <el-col>
            <span class="header-title">区县：</span>
            <el-select
              v-model="form.countyCode"
              placeholder="请选择"
              clearable
              no-data-text="请先选择城市"
            >
              <el-option
                v-for="(item,index) in districtList"
                :key="index"
                :label="item.areaName"
                :value="item.areaCode"
              >
              </el-option>
            </el-select>
          </el-col>
          <!-- <el-col>
            <span class="header-title">企业标签：</span>
            <el-select
              v-model="form.companyType"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in companyList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col> -->
					<el-col>
            <span class="header-title">所属企业组：</span>
						<el-select
							v-model="form.groupName"
							filterable
							clearable
							placeholder="请选择"
						>
							<el-option
								v-for="item in groupList"
								:key="item.id"
								:label="item.name"
								:value="item.name"
							>
							</el-option>
						</el-select>
          </el-col>
				<el-form-item label="所属企业组" prop="groupName">
        </el-form-item>
          <el-col>
            <span class="header-title">填报状态：</span>
            <el-select
              v-model="form.type"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in reportList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
					<el-col>
						<el-row type="flex"  justify="end">
							<el-button type="primary" :loading="exportLoading" @click="exportClick"
							>导出表格</el-button>
						</el-row>
					</el-col>
        </el-row>
      </div>
        <Table ref="table" @closeLoad="closeLoad"></Table>
      </div>
    </div>
    <el-row type="flex" justify="center" :gutter="24" class="flex" style="margin:0">
      <el-col :span="24" class="download-box">
        <el-button type="primary" :loading="buttonLoading"  @click="downloadClick">下载分析结果</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Table from './components/table.vue'
import rightList from './components/rightList.vue'
import leftList from './components/leftList.vue'
import { surveyListApi } from '@/api/survey'
import { workbenchAllApi, workbenchAllApi2 } from '@/api/workbench'
import { districtListApi } from '@/api/district'
import {
  getCompanyGroupListApi,
  getCompanyListApi,
  getCompanyDeleteApi,
  sendCompanySmsApi,
} from "@/api/company";
export default {
  components:{Table,rightList, leftList},
  data(){
    return{
      buttonLoading:false,
      loading:false,
      title:'',
      wjdoId:'',
      monthList:[],
      form:{
        cityCode:'',
        countyCode:'',
        companyType:'',
        type:''
      },
      districtList:[],
      companyList:this.enterpriseLabel,
      reportList:[{
        label:'已填报',
        value:1
      },{
        label:'未填报',
        value:2
      }],
      allObj:{},
      cityName:'',
      user:{},
      ranking:'',
			groupList:[],
			exportLoading:false
    }
  },
  watch:{
    form:{
      handler(n){
        this.$refs.table.onLoad(this.wjdoId,n)
      },
      deep: true
      
    },
  },
  created() {
		this.getCompanyGroupListFun()
    this.surveyListFun()
    this.user = JSON.parse(localStorage.getItem('adminUser'))
    this.form.cityCode = this.user.cityCode
    this.districtListFun()
  },
  methods:{
    // 获取企业组
    async getCompanyGroupListFun() {
      // var start = currentPage ? currentPage * pageSize - pageSize : 0
      var params = {
        start: 0,
        length: 10000,
        draw: 1,
        orderStr: "time desc",
        status:0
      };
      var res = await getCompanyGroupListApi(params);
      this.groupList = res.data;
    },
    // 获取地区 - 城市
    // async cityListFun(){
    //   var list = []
    //   var params = {
    //       start: 0,
    //       length: 10000,
    //       draw: 1,
    //       status:0,
    //       orderStr:'id desc'
    //   }
    //   var res = await districtListApi(params)
    //   res.data.map(item => {
    //     var obj={
    //       areaPname:item.areaPname,
    //       areaPcode:item.areaPcode
    //     }
    //     list.push(obj)
    //   })
    //   const map = new Map();
    //   this.city = list.filter(v => !map.has(v.areaPcode) && map.set(v.areaPcode, 1));
    // },
     // 获取地区 - 区县
    async districtListFun(){
      var params = {
          start: 0,
          length: 10000,
          draw: 1,
          status:0,
          orderStr:'id desc',
          areaPcode: this.user.cityCode
      }
      var res = await districtListApi(params)
      this.cityName = res.data[0].areaPname
      res.data.map(item => {
        var objs={
          areaName:item.areaName,
          areaCode:item.areaCode
        }
        this.districtList.push(objs)
      })
    },
		closeLoad(){
			this.exportLoading = false
		},
		exportClick(){
			this.exportLoading = true
			//去table组件中拿值，导出
			this.$refs.table.onExport(this.wjdoId,this.form)
		},
    // 获取问卷数据
    async surveyListFun(){
      var params={
          start: 0,
          length: 10000,
          draw: 1,
          orderStr:'time desc'
      }
      var res = await surveyListApi(params)
      this.monthList = res.data
      this.title = res.data[0].wjdoTitle
      this.wjdoId = res.data[0].wjdoId
      this.$refs.table.onLoad(this.wjdoId,this.form)
      //this.workbenchAllFun()
			this.workbenchAllFun2()
    },
    monthClick(item){
      this.loading=true
      this.title = item.wjdoTitle
      this.wjdoId = item.wjdoId
      //this.workbenchAllFun()
			this.workbenchAllFun2()
      this.$refs.table.onLoad(this.wjdoId,this.form)

    },
   // 请求 填报率，准确率，柱状图，得分列表
   async workbenchAllFun(){
      var params={
        wjdoId:this.wjdoId 
      }
      var res = await workbenchAllApi(params)
      this.loading=false
      for(var i=0;i<res.data.l1.length;i++){
        if(res.data.l1[i].ranking){
          this.ranking = res.data.l1[i].ranking
        }
      }
      this.allObj = res.data
      var obj={
        // k1: res.data.k1,
        // k2: res.data.k2,
        k3: res.data.k3,
        k4: res.data.k4
      }
      this.$refs.leftList.onLoad(obj)
      this.$refs.rightList.onLoad(res.data.l1,res.data.l2)
      //this.workbenchAllFun2()
      
    },
    async workbenchAllFun2(){
      var params={
        wjdoId:this.wjdoId 
      }
      var res = await workbenchAllApi2(params)
      console.log('resresresres', res)
      this.loading=false
      // this.allObj = res.data
      this.allObj = {...this.allObj, ...res.data}
      console.log('allObj', this.allObj)
      var obj={
				//全省企业数量
        k1: res.data.k1,
				//全省填报企业数量
        k2: res.data.k2,
				//总的填报率
        submitRate: res.data.submitRate,
				//总参与企业数
        submitTotal: res.data.submitTotal,
				//样本填报率
        ybSubmitRate: res.data.ybSubmitRate,
      }
      this.$refs.leftList.onLoad2(obj)
    },
   
    downloadClick(){
      console.log('下载模板');
      this.buttonLoading=true
      this.download(
                '/doftec/admin-survey/download?id='+this.wjdoId ,
                // { params: params },
                `${this.title}.xlsx`
            )
            this.buttonLoading=false
    },
  }
}
</script>
<style scoped>
.title-box {
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid rgba(233, 233, 233, 1);
}
.header-box {
  padding: 10px 20px 10px 40px;
}
.header-title {
  font-size: 14px;
}
.title-tit-box{
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.title-tit {
  font-size: 20px;
  font-weight: bold;
}
.title-pro {
  color: #1890ff;
}
.title-t {
  font-size: 15px;
  font-weight: bold;
}

.content-box {
  margin: 20px 20px 20px 20px;
}
.content-bottom {
  background-color: #fff;
  margin-top: 20px;
}
.content-top {
  height: 100%;
}
.content-top-right {
  background-color: #fff;
  padding: 10px;
  height: 680px;
  /* overflow: hidden; */
}
.content-top-left {
  /* padding: 10px; */
}
.title {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
  color: #000;
}
.el-dropdown-link{
  color: #1890ff;
}
.box{
  /* position: relative;
  overflow: hidden; */
}
.flex{
  position: sticky;
  bottom: 0px;
  background-color: #fff;
  /* left: 272px; */
  /* right: 0; */
  z-index: 99;
  box-shadow: 0px -2px 7px -3px rgba(0, 0, 0, 0.1);
}
.download-box{
  text-align: center;
  padding: 10px;
}
.title-header{
  /* position: fixed; */
  /* bottom: 0; */
  background-color: #fff;
  /* left: 260px; */
  /* right: 0; */
  position: sticky;
  top: 0px;
  z-index: 99;
  box-shadow: 0px 2px 7px -3px rgba(0, 0, 0, 0.1);
}
.dropdown-box{
  height: 500px;
  overflow: scroll;
}
</style>